import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IndividualPageLayoutTemplate from '../components/IndividualPageLayoutTemplate'

export const AboutTemplate = ({
    pageName,
    aboutList,
    pageBannerTitle,
    pageBannerImage,
    pageBannerDescription
}) => {


  return (
    <>
         <IndividualPageLayoutTemplate 
            pageName={pageName}
            bannerImg={pageBannerImage}
            bannerTitle={pageBannerTitle}
            bannerDescription={pageBannerDescription}
            topicList={aboutList}
        />
    </>
  )
}

AboutTemplate.propTypes = {
    pageName: PropTypes.string,
    aboutList: PropTypes.array,
    pageBannerTitle: PropTypes.string,
    pageBannerDescription: PropTypes.string,
    pageBannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }

  const AboutPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
      return (
        <Layout
        title={frontmatter.pageBannerTitle}
        description='We are the first human centered artificial intelligence lab that has capabilities to leverage the most cutting-edge software and smart system development with most effective technologies.'
        >
              <AboutTemplate
                pageName={frontmatter.pageName}
                aboutList={frontmatter.aboutList}
                pageBannerImage={frontmatter.pageBannerImage}
                pageBannerDescription={frontmatter.pageBannerDescription}
                pageBannerTitle={frontmatter.pageBannerTitle}
              />
        </Layout>
      )
}

AboutPage.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
      frontmatter {
        pageName
        pageBannerTitle
        pageBannerDescription
        pageBannerImage {
          extension
          publicURL
        }
        aboutList {
            title
            description
            thumbnail {
              extension
              publicURL
            }
          }

      }
    }
  }
`
